import React, { Component } from 'react'

class UserGreeting extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             isLoggedIn: true
        }
    }
    

    render() {  // if else state is not valid inside JSX.

        return this.state.isLoggedIn && <div>Welcome Vishwas</div>

        // return(
        //     this.state.isLoggedIn ? (
        //     <div>Welcome Vishwas</div>
        //     ) : (
        //     <div>Welcome Guest</div>
        //     )
        // )

        // let message
        // if (this.state.isLoggedIn) {
        //     message = <div>Welcome Vishwas</div>
        // } else {
        //     message = <div>Welcome Guest</div>
        // }

        // return <div>{message}</div>


        // if (this.state.isLoggedIn) {
        //     return (
        //         <div>
        //             Welcome Vishwas
        //         </div>
        //     )
        // } else {
        //     return <div>Welcome Guest</div>
        // }

        // return (
        //     <div>
        //         <div>WElcome Vishwas</div>
        //         <div>Welcome Guest</div>
        //     </div>
        // )
    }
}

export default UserGreeting
